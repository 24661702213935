import {
  Button,
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';

export interface TwoActionsCardWidgetProps {
  title: string;
  description: string;
  image: string;
  imageAltText: string;
  actions: { title: string; link: string }[];
}

export const TwoActionsCardWidget = ({
  title,
  description,
  image,
  imageAltText,
  actions,
}: TwoActionsCardWidgetProps) => {
  const history = useHistory();

  return (
    <Card.Elevated
      css={{
        padding: '0',
        boxShadow: '0px 2px 6px 0px #0000001F !important',
        borderRadius: '8px',
        maxWidth: '100%',
        border: 'none',
        backgroundColor: 'inherit',
        alignItems: 'center',
      }}
      imagePosition="end"
    >
      <Card.Image
        image={image}
        imageAlt={imageAltText}
        css={{
          width: '98px',
          height: '98px',
          objectFit: 'cover',
          marginRight: '24px',
        }}
      />
      <Card.Section>
        <HeadingText level="2" size="sm">
          {title}
        </HeadingText>
        <ParagraphText
          css={{
            width: '180px',
            '@mobile': { width: '100%' },
          }}
        >
          {description}
        </ParagraphText>
        <StackLayout
          css={{
            marginTop: '$oneAndHalf',
          }}
        >
          {actions[0].title && (
            <ParagraphText
              css={{
                width: '180px',
                marginTop: '-20px',
                '@mobile': { width: '100%' },
              }}
            >
              {actions[0].title}
            </ParagraphText>
          )}
          <Button
            priority="secondary"
            onClick={() => history.push('/provider-redirect')}
            width={{ '@initial': 'hugContents', '@mobile': 'fillContainer' }}
            css={{
              borderRadius: '24px',
              paddingX: '$one !important',
              marginTop: '$one',
              borderColor: '$tertiaryBorderDefault',
              color: '$tertiaryBorderDefault',
              height: '$two',
              '&:hover': {
                backgroundColor: 'inherit',
                color: 'inherit',
                borderColor: 'inherit',
              },
            }}
          >
            {actions[1].title}
          </Button>
        </StackLayout>
      </Card.Section>
    </Card.Elevated>
  );
};
