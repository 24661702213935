import { useState } from 'react';

export const useMailingAddress = (initialCurrentAddress = '') => {
  const [currentAddress, setCurrentAddress] = useState(initialCurrentAddress);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [newAddress, setNewAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  });
  const [isSameAddress, setIsSameAddress] = useState(true);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleNewAddressChange = (field: string, value: string) => {
    setNewAddress((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const confirmAddress = (isConfirmedSame: boolean) => {
    setIsSameAddress(isConfirmedSame);
  };

  const validateNewAddress = () => {
    const { line1, city, state, zip } = newAddress;
    return line1 && city && state && /^\d{5}(-\d{4})?$/.test(zip);
  };

  const resetNewAddress = () => {
    setNewAddress({
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',
    });
    setIsSameAddress(true);
    setErrors({});
  };

  return {
    currentAddress,
    setCurrentAddress,
    newAddress,
    handleNewAddressChange,
    isSameAddress,
    confirmAddress,
    validateNewAddress,
    resetNewAddress,
    currentPhoneNumber,
    setCurrentPhoneNumber,
    errors,
    setErrors,
  };
};
