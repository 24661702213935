import { StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { getMasonryDriver } from '@leagueplatform/masonry';
import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { useLocation } from '@leagueplatform/routing';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { MainPageContainer } from '@leagueplatform/web-common-components';

export const AuthorizationDetailPage: React.FC = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_AUTHORIZATIONS' }));

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const authId = params.get('authId') || '';

  const MasonryDriver = getMasonryDriver({
    rootNodeId: 'authorizationDetails$root',
    queryParams: { authId },
  });

  return (
    <MainPageContainer
      width="100%"
      css={{
        '.GDS-vertical-stack-layout': {
          width: '100%',
        },
      }}
    >
      <StackLayout
        orientation="vertical"
        css={{
          maxWidth: '894px',
          gap: '12px',
        }}
      >
        <MasonryEngine driver={MasonryDriver} />
      </StackLayout>
    </MainPageContainer>
  );
};
