import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import camelcaseKeys from 'camelcase-keys';
import { MESSAGE_TYPES } from 'commons/constants';

interface AppInitDetailsReturn {
  [key: string]: unknown;
  eligibilityInfo: {
    eligible: boolean;
    reasonCodeDescription?: string;
    reasonCode: number;
  };
}

export const getAppInitDetails = (): Promise<AppInitDetailsReturn> =>
  SocketAsFetch.fetch({
    message_type: MESSAGE_TYPES.GET_APP_INIT_DETAILS,
    info: {
      platform: 'web',
    },
  }).then((data: any) => camelcaseKeys(data, { deep: true }));
