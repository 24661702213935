import React from 'react';
import {
  ParagraphText,
  StackLayout,
  HeadingText,
  Card,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export interface AuthorizationServiceInfoSectionProps {
  heading: string;
  info: { key: string; value: string }[];
  isVisible: boolean;
}

export const AuthorizationServiceInfoSection: React.FC<
  AuthorizationServiceInfoSectionProps
> = ({ heading, info, isVisible }) => {
  const { formatMessage } = useIntl();

  // Map translation keys for info keys
  const getTranslatedKey = (key: string): string => {
    const translationKeys: Record<string, string> = {
      Name: 'STR_NAME',
      ID: 'STR_ID',
      Address: 'STR_ADDRESS',
      'Phone Number': 'STR_PHONE_NUMBER',
    };

    return formatMessage({ id: translationKeys[key] || (key as any) });
  };

  if (!isVisible) return null;

  return (
    <>
      <HeadingText
        level="3"
        size="md"
        css={{
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '28px',
          marginTop: '$oneAndHalf',
        }}
      >
        {heading}
      </HeadingText>
      <Card.Elevated
        css={{
          display: 'flex',
          flexDirection: 'column',
          paddingY: '$half',
          boxShadow: 'none',
          borderRadius: '8px',
          backgroundColor: 'inherit',
          border: '1px solid #D6D6D6',
        }}
      >
        <StackLayout
          orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
          css={{ gap: '$one', alignItems: 'start', padding: '$two' }}
        >
          {info.map(({ key, value }) => (
            <StackLayout
              key={key}
              orientation="vertical"
              css={{
                padding: '$half',
              }}
            >
              <ParagraphText css={{ fontWeight: 700 }}>
                {getTranslatedKey(key)}
              </ParagraphText>
              <ParagraphText>{value}</ParagraphText>
            </StackLayout>
          ))}
        </StackLayout>
      </Card.Elevated>
    </>
  );
};
