export const isMobileUserAgent = () => {
  const userAgent = window.navigator.userAgent || '';
  const isAndroidWebView =
    /Android/i.test(userAgent) && /wv|WebView/i.test(userAgent);
  const isIOSWebView =
    /iPhone|iPad|iPod/i.test(userAgent) &&
    !/Safari/i.test(userAgent) &&
    /AppleWebKit/i.test(userAgent);

  return isAndroidWebView || isIOSWebView;
};
