import React from 'react';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { CustomerInfoChangeForm } from '../../common/constants';

export const MakeUpdateCard = () => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      css={{
        paddingTop: '$two',
      }}
    >
      <UtilityText css={{ typography: '$bodyOne' }}>
        {`${formatMessage({ id: 'STR_SUNMIT_A_REQUEST' })} `}
        <a
          href={`/health-journey/campaign/${CustomerInfoChangeForm.campaignId}/activity/${CustomerInfoChangeForm.activityId}/toolbox`}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 400 }}
        >
          {formatMessage({ id: 'STR_MEMBER_CONTACT_INFORMATION_CHANGE' })}
        </a>
        {` ${formatMessage({ id: 'STR_FORM' })}`}.
      </UtilityText>
    </StackLayout>
  );
};
