import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { captureAppError } from '@leagueplatform/observability';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { SENTRY_CONSTANTS } from 'commons/constants';

// Event sent when a capability nav link is clicked
export const sendCapabilityNavLinkEvent = (action: string) => () =>
  trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
    screen_name: 'Navigation Bar',
    detail: action,
  });

// Analytics properties for when a user option is clicked
export const getUserOptionAnalyticsProps = (action: string) => ({
  detail: 'option clicked',
  product_area: PRODUCT_AREA.SETTINGS,
  screen_name: SCREEN_NAMES.SETTINGS,
  sub_detail: action,
});

// Event sent when a user option is clicked in the user dropdown or mobile menu
export const sendUserOptionEvent = (action: string) =>
  trackAnalyticsEvent(
    EVENT_NAME.BUTTON_CLICKED,
    getUserOptionAnalyticsProps(action),
  );

export const setAnalyticsEvent = async (payload: any) => {
  try {
    return await SocketAsFetch.fetch({
      message_type: 'set_analytics_event',
      info: payload,
    });
  } catch (error) {
    if (error instanceof Error) {
      captureAppError(error, {
        ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
        errorName: 'Error setting Analytics',
      });
    }
    return null;
  }
};
