import React from 'react';
import {
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
  TextAction,
  UtilityText,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import { AuthorizationStatus } from 'utils/get-translated-status-label';
import { AuthorizationStatusBar } from 'components/authorization-status-banner.component';

export interface AuthorizationCardProps {
  heading: string;
  body: string;
  status: AuthorizationStatus;
  eyebrow: string;
  onClick: any;
}

export const AuthorizationCard: React.FC<AuthorizationCardProps> = ({
  heading,
  body,
  status,
  eyebrow,
  onClick,
}) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  return (
    <Card.Elevated
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '$half',
        boxShadow: '0px 2px 6px 0px #0000001F',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: 'inherit',
        width: '100%',
      }}
    >
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
        imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
        css={{
          flexDirection: 'column',
          border: 'none !important',
          padding: '24px',
        }}
      >
        <TextAction as="a" href={onClick.payload.url} ref={primaryActionRef}>
          <StackLayout
            css={{
              flex: 1,
            }}
          >
            <UtilityText
              size="xs"
              emphasis="subtle"
              css={{ typography: '$overline', marginBottom: '$half' }}
            >
              {eyebrow}
            </UtilityText>
            <HeadingText
              level="3"
              size="sm"
              css={{ typography: '$subtitleOne', marginBottom: '4px' }}
            >
              {heading}
            </HeadingText>
            <ParagraphText
              css={{
                whiteSpace: 'pre-line',
                typography: '$bodyTwo',
                marginBottom: '$half',
              }}
            >
              {body}
            </ParagraphText>

            <AuthorizationStatusBar status={status} />
          </StackLayout>
        </TextAction>
      </Card.ClickArea>
    </Card.Elevated>
  );
};
