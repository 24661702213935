import * as React from 'react';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { useLocation } from '@leagueplatform/routing';
import { ImpersonationTopBanner } from '@leagueplatform/web-common-components';
import { AppNavBarComponent } from 'components/header-nav/Nav';
import { FooterComponent } from './footer/footer.component';

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
}>;

const CLAIMS_PAGE_ICON_COLOR_STYLES = {
  color: '$primaryBackgroundDefault',
  filter:
    'invert(36%) sepia(94%) saturate(750%) hue-rotate(-14deg) brightness(90%) contrast(85%)',
};

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
}) => {
  const location = useLocation();

  const isGetCarePage = location.pathname === '/get-care';
  const isChangePCPPage = location.pathname === '/change-pcp';
  const isProviderRedirect = location.pathname === '/provider-redirect';
  const isClaimsPage = location.pathname === '/claims';
  const isContactInfo = location.pathname === '/contact_info';

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflowX: 'hidden',
        '& [data-testid="img-icon"], & [data-testid="svg-icon"]': isClaimsPage
          ? CLAIMS_PAGE_ICON_COLOR_STYLES
          : {},
        '& .user-dropdown-item_link:focus::before': {
          boxShadow: 'none',
        },
        '.GDS-button': {
          borderRadius: '99999px',
          whiteSpace: 'nowrap',
          padding: '12px 10px',
        },
        ...css,
      }}
    >
      {isPrivatePage && !isProviderRedirect && !isContactInfo && (
        <Box css={{ flexShrink: 0 }}>
          <ImpersonationTopBanner />
          <AppNavBarComponent />
        </Box>
      )}
      <Box
        as="main"
        css={{
          flex: '1 0 auto',
          position: 'relative',
          overflowX: 'hidden',
          a: isGetCarePage ? { borderRadius: '99999px' } : undefined,
          ul: { listStyleType: 'none', paddingLeft: 0 },
          ...css,
        }}
      >
        {children}
      </Box>
      {isPrivatePage &&
        !isChangePCPPage &&
        !isProviderRedirect &&
        !isContactInfo && (
          <Box css={{ flexShrink: 0 }}>
            <FooterComponent />
          </Box>
        )}
    </Box>
  );
};
