import React from 'react';
import {
  ParagraphText,
  RadioIndicator,
  StackLayout,
} from '@leagueplatform/genesis-core';
import AddressCard from './address-card.component';

interface RadioAddressOptionProps {
  id: string;
  name: string;
  value: string;
  isSelected: boolean;
  onSelect: () => void;
  titleId: string;
  addressLabelId: string;
  address: string;
  phone?: string;
}

const RadioAddressOption: React.FC<RadioAddressOptionProps> = ({
  id,
  name,
  value,
  isSelected,
  onSelect,
  titleId,
  addressLabelId,
  address,
  phone,
}) => (
  <>
    <StackLayout
      orientation="horizontal"
      spacing="$oneAndHalf"
      css={{ alignItems: 'flex-start', width: '100%' }}
    >
      <RadioIndicator
        name={name}
        id={id}
        value={value}
        checked={isSelected}
        onChange={onSelect}
      />
      <ParagraphText
        css={{
          paddingBottom: '$oneAndHalf',
        }}
      >
        {titleId}
      </ParagraphText>
    </StackLayout>
    <AddressCard
      addressLabelId={addressLabelId}
      address={address}
      phone={phone}
    />
  </>
);

export default RadioAddressOption;
