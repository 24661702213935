import { useIntl } from '@leagueplatform/locales';

export enum ErrorTypes {
  API_ERROR = 'api_error',
  API_CONNECTION_ERROR = 'api_connection_error',
  NO_CONNECTION = 'no_connection',
  NOT_ELIGIBLE = 'not_eligible',
}

type ErrorBody = {
  headingId: string;
  captionId?: string;
};

const ErrorMessages: Record<ErrorTypes, ErrorBody> = {
  [ErrorTypes.NO_CONNECTION]: {
    headingId: 'STR_ERROR_HEADING_NO_CONNECTION',
    captionId: 'STR_ERROR_CAPTION_NO_CONNECTION',
  },
  [ErrorTypes.API_ERROR]: {
    headingId: 'STR_ERROR_HEADING_GENERIC',
    captionId: 'STR_ERROR_CAPTION_BACKEND',
  },
  [ErrorTypes.API_CONNECTION_ERROR]: {
    headingId: 'STR_ERROR_HEADING_GENERIC',
    captionId: 'STR_ERROR_CAPTION_NO_CONNECTION',
  },
  [ErrorTypes.NOT_ELIGIBLE]: {
    headingId: 'STR_ELIGIBILITY_ERROR_HEADING',
    captionId: 'STR_ERROR_ELIGIBILITY_CAPTION',
  },
};

export const useErrorMessage = (
  errorType: ErrorTypes,
  customMessage?: string,
) => {
  const { formatMessage } = useIntl();

  return {
    errorHeading: formatMessage({
      id: ErrorMessages[errorType].headingId,
    } as never),
    errorCaption:
      customMessage ||
      formatMessage({ id: ErrorMessages[errorType].captionId } as never),
  };
};
