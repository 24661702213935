import React from 'react';
import { useIntl } from '@leagueplatform/locales';

import { StackLayout } from '@leagueplatform/genesis-core';
import { CONTENT_MAX_WIDTH } from '@leagueplatform/web-common-components';
import { HeaderNavBarProps } from './nav-types.types';
import { SkipToMainContentLink } from './skip-to-main-content-link.component';

export const HEADER_NAV_BAR_DEFAULT_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid

/**
 * An app nav bar that stays at the top of the screen.
 * @param {object} props
 * @prop {string} props.children - child components that are to be displayed in the app bar. These should be <li> elements.
 * @prop {number} [props.navHeight] - the height of the nav bar.
 * @prop {boolean} [props.enableSkipToContent] - adds the skip to main content link item to the nav bar.
 * @prop {GDSStyleObject} [props.headerStyling] - Genesis css styling overrides for the wrapping <header> element.
 * @prop {GDSStyleObject} [props.navListStyling] - Genesis css styling overrides for the inner navigation <ul> element
 */
export const HeaderNavBar = ({
  children,
  navHeight = HEADER_NAV_BAR_DEFAULT_HEIGHT,
  enableSkipToContent = true,
  headerStyling,
  navListStyling,
}: HeaderNavBarProps) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      id="app_nav"
      as="header"
      role="banner"
      horizontalAlignment="spaceBetween"
      data-testid="main-header"
      orientation="horizontal"
      verticalAlignment="stretch"
      css={{
        color: '$onSurfaceTextSubdued',
        height: navHeight,
        paddingRight: '$quarter',
        paddingLeft: '$quarter',
        backgroundColor: '$surfaceBackgroundPrimary',
        boxShadow: '$dropdown',
        position: 'sticky',
        top: 0,
        zIndex: '$sticky',
        '@laptop': {
          paddingRight: '$one',
          paddingLeft: '$one',
        },
        '@desktop': {
          paddingRight: '$three',
          paddingLeft: '$three',
        },
        ...headerStyling,
      }}
    >
      {enableSkipToContent && (
        <SkipToMainContentLink
          css={{
            top: '-400%',
          }}
        />
      )}
      <StackLayout
        as="nav"
        aria-label={formatMessage({ id: 'STR_MAIN_NAVIGATION_NAV_LABEL' })}
        orientation="horizontal"
        verticalAlignment="stretch"
        horizontalAlignment="center"
      >
        <StackLayout
          as="ul"
          data-testid="header-nav-ul"
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
          css={{
            alignItems: 'stretch',
            maxWidth: CONTENT_MAX_WIDTH,
            ...navListStyling,
          }}
        >
          {children}
        </StackLayout>
      </StackLayout>
    </StackLayout>
  );
};
