import React from 'react';
import {
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';

interface DetailItemProps {
  label: string;
  value: string;
  isLastItem?: boolean;
}

const DetailItem: React.FC<DetailItemProps> = ({
  label,
  value,
  isLastItem,
}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '2px 0',
      width: '100%',
      borderBottom: isLastItem ? 'none' : '1px solid #D6D6D6',
    }}
  >
    <ParagraphText
      css={{
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '16px',
        textAlign: 'left',
      }}
    >
      {label}
    </ParagraphText>
    <ParagraphText css={{ typography: '$subtitleTwo' }}>{value}</ParagraphText>
  </div>
);

interface PCPInfoCardProps {
  title: string;
  pcpName: string;
  clinicName: string;
  clinicAddress: string;
  phoneNumber: string;
  isHighlighted?: boolean; // New prop
}

const PCPInfoCard: React.FC<PCPInfoCardProps> = ({
  title,
  pcpName,
  clinicName,
  clinicAddress,
  phoneNumber,
  isHighlighted = false,
}) => {
  const details = [
    { label: 'PCP Name', value: pcpName },
    { label: 'Clinic Name', value: clinicName },
    { label: 'Clinic Address', value: clinicAddress },
    { label: 'Phone Number', value: phoneNumber },
  ];

  return (
    <Card.Elevated
      css={{
        padding: '24px',
        boxShadow: 'none',
        borderRadius: '9px',
        border: isHighlighted ? '1px solid #5C6370' : '1px solid #D6D6D6',
        width: '100%',
        maxWidth: '888px',
        backgroundColor: '$surfaceBackgroundPrimary',
        '@media (max-width: 600px)': { maxWidth: '100%' },
      }}
    >
      <HeadingText
        level="3"
        size="sm"
        css={{
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '28px',
          marginBottom: '24px',
        }}
      >
        {title}
      </HeadingText>
      <StackLayout
        css={{
          gap: '8px',
        }}
      >
        {details.map((detail, index) => (
          <DetailItem
            key={detail.label}
            label={detail.label}
            value={detail.value}
            isLastItem={index === details.length - 1}
          />
        ))}
      </StackLayout>
    </Card.Elevated>
  );
};

export default PCPInfoCard;
