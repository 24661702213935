import { useEffect, useState } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { Spinner } from '@leagueplatform/genesis-core';
import { useHistory, useLocation } from '@leagueplatform/routing';
import {
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';

export const AuthViewLogout = () => {
  useEffect(() => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
      federated: true,
    });
  }, []);

  return <LoadingIndicator />;
};

export const AuthViewLogin = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect({});
  }, []);

  return <LoadingIndicator />;
};

// Gets auth0 error returned from redirect, the returned error could be a query param or a hash param
// const getAuth0ErrorIfExists = (location: Location) => {
//   const searchParams = new URLSearchParams(location.search);
//   const hashParams = new URLSearchParams(location.hash.replace(/#/g, '?'));

//   const type = searchParams.get('error') || hashParams.get('error');
//   const description =
//     searchParams.get('error_description') ||
//     hashParams.get('error_description');

//   if (type) {
//     return { type, description };
//   }

//   return null;
// };

export const AuthStartImpersonationSession = () => {
  const location = useLocation();
  const history = useHistory();

  const [error, setError] = useState('');
  const AUTH0_CLIENT_CONNECTION = 'scfhp-internal-enterprise-saml';

  useEffect(() => {
    const connection =
      new URLSearchParams(location.search).get('connection') ||
      AUTH0_CLIENT_CONNECTION;

    // const authError = getAuth0ErrorIfExists(location);
    // if (authError) {
    // setError(`${authError.type}: ${authError.description || ''}`);
    // captureAuthenticationError(authError);
    //   return;
    // }

    try {
      const options = {
        connection,
      };

      StandaloneAuth.client.loginWithRedirect(options);
    } catch (e: unknown) {
      // to do add logging
      // logger.error(e);
      setError(e instanceof Error ? e.message : String(e));
    }
  }, [location, history]);

  if (error) {
    return <ErrorState bodyString={error} hideButton />;
  }
  return <Spinner />;
};
