/**
 * Formats a given zip code based on its length.
 * - If it's 5 digits, it returns it as-is.
 * - If it's 9 digits, it formats as DDDDD-DDDD.
 * - For any other length, it returns the input unchanged.
 *
 * @param zipCode - The input zip code string from the backend.
 * @returns The formatted zip code or the original input if invalid.
 */
export const formatZipCode = (zipCode: string): string => {
  if (!/^\d+$/.test(zipCode)) {
    return zipCode;
  }

  if (zipCode.length === 5) {
    return zipCode;
  }

  if (zipCode.length === 9) {
    return `${zipCode.slice(0, 5)}-${zipCode.slice(5)}`;
  }

  return zipCode;
};
