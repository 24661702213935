import React from 'react';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import PCPInfoCard from 'components/cards/pcp-info-card.component';
import HeaderSection from './header-section.component';

interface StageProps {
  title: string;
  pcps: Array<any>;
}

const PCPReviewStage: React.FC<StageProps> = ({ title, pcps }) => (
  <Box
    css={{
      maxWidth: '952px',
      width: '100%',
      margin: '0 auto',
      boxShadow: '0px 2px 6px 0px #0000001F',
      overflow: 'hidden',
      padding: '32px',
      '@mobile': {
        padding: '0.7em',
        boxShadow: 'none',
      },
    }}
  >
    <HeaderSection title={title} />

    <StackLayout
      css={{
        gap: '16px',
        width: '100%',
        marginTop: '$two',
        padding: '32px',
        '@mobile': {
          padding: '0',
        },
      }}
    >
      {pcps.map((pcp, index) => (
        <PCPInfoCard key={pcp.title} isHighlighted={index === 1} {...pcp} />
      ))}
    </StackLayout>
  </Box>
);

export default PCPReviewStage;
