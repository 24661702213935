import React from 'react';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { pcpFormModel } from 'hooks/use-change-pcp.hook';
import RadioAddressOption from './radio-address-option.component';
import RadioAddressFormOption from './radio-address-form-option.component';
import HeaderSection from './header-section.component';

interface AddressSelectionProps {
  selectedOption: string;
  currentAddress: string;
  currentPhoneNumber: string;
  title: string;
  subTitle: string;
  formAddress: Record<string, string>;
  onOptionChange: (option: string) => void;
  onFormChange: (field: string, value: string) => void;
}

const AddressSelection: React.FC<AddressSelectionProps> = ({
  selectedOption,
  currentAddress,
  currentPhoneNumber,
  formAddress,
  subTitle,
  title,
  onOptionChange,
  onFormChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      css={{
        maxWidth: '952px',
        width: '100%',
        margin: '0 auto',
        boxShadow: '0px 2px 6px 0px #0000001F',
        overflow: 'hidden',
        padding: '32px',
        '@mobile': {
          padding: '0.7em 1em 0.7em',
          boxShadow: 'none',
        },
      }}
    >
      <HeaderSection title={title} subTitle={subTitle} />
      <StackLayout css={{ maxWidth: '800px', marginTop: '$three' }}>
        <RadioAddressOption
          id="use-listed"
          name="address-selection"
          value="listed"
          isSelected={selectedOption === 'listed'}
          onSelect={() => onOptionChange('listed')}
          titleId={formatMessage({ id: 'STR_USE_LISTED_ADDRESS' })}
          addressLabelId="STR_MAILING_ADDRESS"
          address={currentAddress}
          phone={currentPhoneNumber}
        />

        <RadioAddressFormOption
          id="change-address"
          name="address-selection"
          value="custom"
          isSelected={selectedOption === 'custom'}
          onSelect={() => onOptionChange('custom')}
          formModel={pcpFormModel}
          formAddress={formAddress}
          onFormChange={onFormChange}
          titleId={formatMessage({ id: 'STR_CHANGE_MY_ADDRESS' })}
        />
      </StackLayout>
    </Box>
  );
};

export default AddressSelection;
