import React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { ContactDetails } from './contact-details.component';

interface ContactDetailsItem {
  title: string;
  email: string;
  phone: string;
  hours: string;
}

interface ContactDetailsListProps {
  items: ContactDetailsItem[];
  variant?: 'centered' | 'default';
}

export const ContactDetailsList: React.FC<ContactDetailsListProps> = ({
  items,
  variant,
}) => (
  <StackLayout
    css={{
      gap: '24px',
      width: '100%',
    }}
  >
    {items.map((item) => (
      <ContactDetails
        key={item.title}
        title={item.title}
        email={item.email}
        phone={item.phone}
        hours={item.hours}
        variant={variant}
      />
    ))}
  </StackLayout>
);
