import { useLOB } from '@scfhp-web/shared-module';
import { useCallback } from 'react';

export const useProviderRedirect = () => {
  const lob = useLOB();

  const constructRedirectUrl = useCallback(() => {
    const RedirectUri = `${window.location.origin}/change-pcp`;

    const baseUrl =
      'https://apptest.scfhp.com/provideronlinedirectory/initial-provider-results';

    const params = [
      `Embedded=True`,
      `ProviderPlan=${lob}`,
      ...(lob === 'Medi-Cal' ? [`Network=NEMS`] : []),
      `Category=Primary+Care+Physician`,
      `RedirectUri=${encodeURIComponent(RedirectUri)}`,
    ];

    return `${baseUrl}?${params.join('&')}`;
  }, [lob]);

  const handleProviderRedirect = useCallback(() => {
    const url = constructRedirectUrl();
    window.location.href = url;
  }, [constructRedirectUrl]);

  const extractPCPDetails = useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);
    return {
      ProviderName: queryParams.get('ProviderName') || 'Unknown',
      Phone: queryParams.get('Phone') || 'No Phone Provided',
      ClinicAddress: queryParams.get('ClinicAddress') || 'No Address Provided',
      ClinicName: queryParams.get('ClinicName') || 'Unknown Clinic',
    };
  }, []);

  return { constructRedirectUrl, handleProviderRedirect, extractPCPDetails };
};
