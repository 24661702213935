import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useHistory } from '@leagueplatform/routing';
import { MasonryWSDriverConfig } from '@leagueplatform/masonry-api';
import { getMasonryWSDriver } from '@leagueplatform/masonry';
import { MasonryEngine } from '@leagueplatform/masonry-engine';
import {
  PRODUCT_AREA,
  SCREEN_NAMES,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';

const config: MasonryWSDriverConfig = {
  appId: 'scfhp_care_homepage_left',
  screenName: SCREEN_NAMES.HOMEPAGE,
  productArea: PRODUCT_AREA.GET_CARE,
};

const MasonryFormsPageDriver = getMasonryWSDriver(config);

export const TempCareLandingPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_GET_CARE' }));

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: 'get care page',
  });

  return (
    <MainPageContainer
      width="100%"
      css={{
        '.GDS-vertical-stack-layout': {
          width: '100%',
        },
        'div > div:first-child > div:first-child > div:first-child > div:first-child > div > div:first-child > div':
          {
            backgroundColor: '#FFF9F3',
          },
      }}
    >
      <HeroSection
        title={formatMessage({ id: 'STR_GET_CARE' })}
        goBack={history.goBack}
        height="154px"
      />

      <StackLayout orientation="vertical">
        <MasonryEngine driver={MasonryFormsPageDriver} />
      </StackLayout>
    </MainPageContainer>
  );
};
