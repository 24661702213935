import React from 'react';
import { Box, ParagraphText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface ContactDetailsProps {
  title: string;
  email: string;
  phone: string;
  hours: string;
  variant?: 'default' | 'centered';
}

export const ContactDetails: React.FC<ContactDetailsProps> = ({
  title,
  email,
  phone,
  hours,
  variant = 'default',
}) => {
  const { formatMessage } = useIntl();

  const isCentered = variant === 'centered';

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: isCentered ? 'center' : 'left',
        gap: '4px',
        width: '100%',
      }}
    >
      <ParagraphText
        css={{
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#000',
        }}
      >
        {title}
      </ParagraphText>

      <Box
        css={{
          display: 'flex',
          flexDirection: isCentered ? 'row' : 'column',
          justifyContent: isCentered ? 'center' : 'flex-start',
          marginTop: isCentered ? '0' : '$one',
        }}
      >
        <ParagraphText
          css={{
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '20px',
            paddingRight: isCentered ? '4px' : '0',
            color: '#000',
          }}
        >
          {`${formatMessage({ id: 'STR_EMAIL' })}${isCentered ? ':' : ''}`}
        </ParagraphText>
        <ParagraphText
          css={{
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '20px',
            textDecoration: 'none',
            color: '$interactiveActionPrimary',
          }}
        >
          {email}
        </ParagraphText>
      </Box>

      <Box
        css={{
          display: 'flex',
          flexDirection: isCentered ? 'row' : 'column',
          justifyContent: isCentered ? 'center' : 'flex-start',
          marginTop: '$one',
        }}
      >
        <ParagraphText
          css={{
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '20px',
            paddingRight: isCentered ? '4px' : '0',
            color: '#000',
          }}
        >
          {`${formatMessage({ id: 'STR_BY_PHONE' })}${isCentered ? ':' : ''}`}
        </ParagraphText>
        <ParagraphText
          css={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#000',
          }}
        >
          {phone}
        </ParagraphText>
      </Box>

      <ParagraphText
        css={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#666',
          textAlign: isCentered ? 'center' : 'left',
        }}
      >
        {formatMessage({ id: 'STR_HOURS' })}: {hours}
      </ParagraphText>
    </Box>
  );
};
