import { useEffect } from 'react';
import { captureAppError } from '@leagueplatform/observability';
import { shouldIntercept } from '../utilities/helpers';
import { fetchDataWithToken } from '../utilities/fetch-data-with-token';

export function useInterceptLinkClick(
  pathToIntercept: string,
  rootUrl: string,
): void {
  useEffect(() => {
    const handleClick = async (event: MouseEvent) => {
      let element = event.target as HTMLElement;

      while (element && element.tagName !== 'A' && element.parentElement) {
        element = element.parentElement;
      }

      if (element.tagName === 'A') {
        const href = element.getAttribute('href');
        if (!href) return;

        try {
          const absoluteHref = new URL(href, rootUrl).href;

          if (shouldIntercept(element, pathToIntercept)) {
            event.preventDefault();
            event.stopPropagation();

            const newTab = window.open('', '_blank');
            if (newTab) {
              newTab.document.write(
                '<html><head><title>Loading...</title></head><body></body></html>',
              );
              newTab.document.close();
            }

            fetchDataWithToken(absoluteHref)
              .then((htmlContent: any) => {
                if (newTab) {
                  newTab.document.open();
                  newTab.document.write(htmlContent);
                  newTab.document.close();
                }
              })
              .catch((error: any) => {
                captureAppError(error);
                if (newTab) {
                  newTab.document.open();
                  newTab.document.write('<p>Error loading content</p>');
                  newTab.document.close();
                }
              });

            return;
          }
        } catch (error: any) {
          captureAppError(error);
        }
      }
    };

    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [pathToIntercept, rootUrl]);
}
