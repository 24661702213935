export const parseClinicAddress = (address: string) => {
  const parts = address.split(',').map((part) => part.trim());

  const line1 = parts[0];

  const city = parts[1];

  const stateZip = parts[2]?.split(' ') || [];
  const state = stateZip[0];
  const zip = parts[3];

  const line2 = null;

  return {
    line1,
    line2,
    city,
    state,
    zip,
  };
};
