import {
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import errorIcon from 'assets/error-icon.svg';

export const EmptyState = ({
  heading,
  body,
}: {
  heading: string;
  body: string;
}) => (
  <StackLayout
    orientation="vertical"
    horizontalAlignment="center"
    verticalAlignment="center"
    css={{
      height: '100%',
      whiteSpace: 'pre-wrap',
    }}
  >
    <Image
      src={errorIcon}
      alt=""
      width="138px"
      height="auto"
      position="relative"
      top="minusOne"
      marginBottom="three"
    />
    <HeadingText
      level="1"
      size="xl"
      css={{
        marginBottom: '$half',
      }}
    >
      {heading}
    </HeadingText>
    <ParagraphText emphasis="subtle" css={{ textAlign: 'center' }}>
      {body}
    </ParagraphText>
  </StackLayout>
);
