import React from 'react';
import {
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
  Image,
} from '@leagueplatform/genesis-core';
import { extractPhoneNumber } from 'utils/extract-phone-number';

interface CustomerServiceCardProps {
  title: string;
  description: string;
  actionLink: string;
  body: string;
  icon: string;
}

export const CustomerServiceCard: React.FC<CustomerServiceCardProps> = ({
  title,
  description,
  actionLink,
  body,
  icon,
}) => (
  <>
    <HeadingText
      level="2"
      size="xl"
      css={{
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '0.25px',
        textAlign: 'left',
        marginBottom: '$one',
        marginTop: '$three',
      }}
    >
      Get Help Now
    </HeadingText>
    <Card.Elevated
      css={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxShadow: '0px 2px 6px 0px #0000001F',
        border: 'none',
        padding: '24px',
        borderRadius: '8px',
      }}
    >
      <Image
        src={icon}
        alt="Customer Service"
        css={{
          width: '42px',
          height: '42px',
          marginRight: '12px',
        }}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      />
      <StackLayout
        css={{
          flex: 1,
        }}
      >
        <HeadingText
          level="3"
          size="lg"
          css={{ typography: '$subtitleOne', marginBottom: '4px' }}
        >
          {title}
        </HeadingText>
        <ParagraphText css={{ typography: '$bodyTwo' }}>
          {extractPhoneNumber(actionLink)}
        </ParagraphText>

        <ParagraphText css={{ typography: '$bodyTwo' }}>
          {description}
        </ParagraphText>
        <ParagraphText css={{ whiteSpace: 'pre-line', typography: '$bodyTwo' }}>
          {body}
        </ParagraphText>
      </StackLayout>
    </Card.Elevated>
  </>
);
