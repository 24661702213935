export const LOB_DUAL_CONNECT = 'DualConnect';
export const LOB_MEDI_CAL = 'Medi-Cal';

export const LOB_DEFAULT = 'All';

// Language constants
export const LANG_ENGLISH = 'English';
export const LANG_SPANISH = 'Spanish';
export const LANG_TAGALOG = 'Tagalog';
export const LANG_VIETNAMESE = 'Vietnamese';
export const LANG_CHINESE = 'Chinese';
