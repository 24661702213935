import {
  Button,
  GDSStatus,
  ParagraphText,
  StackLayout,
  StatusBanner,
} from '@leagueplatform/genesis-core';

export const StatusBannerRenderer = ({
  buttonLabel,
  buttonAction,
  body,
  status,
  heading,
}: any) => (
  <StackLayout
    css={{ marginTop: '$three', marginBottom: '$one' }}
    horizontalAlignment="stretch"
  >
    <StatusBanner status={status as GDSStatus} title={heading}>
      {body && (
        <ParagraphText css={{ margin: '$half 0' }}>{body}</ParagraphText>
      )}
      <StackLayout
        orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
      >
        {buttonLabel && buttonAction && (
          <Button
            as="a"
            href={buttonAction}
            priority="primary"
            css={{
              borderRadius: '24px',
              height: '24px',
              fontSize: '12px',
            }}
          >
            {buttonLabel}
          </Button>
        )}
      </StackLayout>
    </StatusBanner>
  </StackLayout>
);
