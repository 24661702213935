import { useEffect, useState } from 'react';
import { StandaloneAuthInitConfig } from '@leagueplatform/auth-standalone';
import { LeagueConfig } from '@leagueplatform/core';
import { useMobileAuthListener } from './use-mobile-auth-listener.hook';

export type StandaloneAuthConfig = StandaloneAuthInitConfig;

type EmbeddedAuthConfig = {
  getUserIdFromMobileHandoff: () => Promise<string>;
  getToken: () => Promise<string>;
};

export type AuthConfig = StandaloneAuthConfig | EmbeddedAuthConfig;

// Custom interface for League Config that does not include `auth` on the core config
export interface AuthlessLeagueConfig extends Omit<LeagueConfig, 'core'> {
  core: Omit<LeagueConfig['core'], 'auth'>;
}

type UseGetLeagueConfigReturn =
  | {
      isLoading: true;
      config: undefined;
    }
  | {
      isLoading: false;
      config: LeagueConfig;
    };

export const useGetLeagueConfig = (
  authlessConfig: AuthlessLeagueConfig,
  standaloneAuthConfig: StandaloneAuthConfig,
): UseGetLeagueConfigReturn => {
  const { shouldUseMobileAuth, mobileAuthPayload } = useMobileAuthListener();

  const [state, setState] = useState<UseGetLeagueConfigReturn>(() =>
    !shouldUseMobileAuth
      ? {
          isLoading: false,
          config: {
            ...authlessConfig,
            core: {
              ...authlessConfig.core,
              auth: standaloneAuthConfig,
            },
          },
        }
      : { isLoading: true },
  );

  useEffect(() => {
    if (shouldUseMobileAuth && mobileAuthPayload) {
      const embeddedAuthConfig: EmbeddedAuthConfig = {
        getToken: () => Promise.resolve(mobileAuthPayload.accessToken),
        getUserIdFromMobileHandoff: () =>
          Promise.resolve(mobileAuthPayload.user['https://el/user_id']),
      };

      setState({
        isLoading: false,
        config: {
          ...authlessConfig,
          core: {
            ...authlessConfig.core,
            auth: embeddedAuthConfig,
          },
        },
      });
    }
  }, [authlessConfig, mobileAuthPayload, shouldUseMobileAuth]);

  return state;
};
