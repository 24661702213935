import { useEffect } from 'react';
import { useProviderRedirect } from 'hooks/use-pcp-provider-redirect.hook';

export const ProviderRedirectPage = () => {
  const { constructRedirectUrl } = useProviderRedirect();

  useEffect(() => {
    const redirectUrl = constructRedirectUrl();

    const timeoutId = setTimeout(() => {
      window.location.href = redirectUrl;
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [constructRedirectUrl]);

  return null;
};
