import {
  StackLayout,
  Checkbox,
  FormField,
  TextInput,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Field, FormikHelpers } from 'formik';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
// import { TermAndConditionsText } from 'components/term-and-conditions-text.component';
import { AccountSetupFormValues } from './account-setup.types';

const analyticsPropsFnMap: {
  [key: string]: (value: boolean) => object;
} = {
  isTermsAccepted: (value) => ({
    detail: 'consent status checkbox',
    consent_status: `member ${value ? 'selects' : 'does not select'} checkbox`,
  }),
  isEmailEnabled: (value) => ({
    detail: 'email opt-in checkbox',
    email_status: `member ${value ? 'selects' : 'does not select'} checkbox`,
  }),
};

const PREFERRED_FIRST_NAME = 'preferredFirstName';
// const IS_TERMS_ACCEPTED = 'isTermsAccepted';
const IS_EMAIL_ENABLED = 'isEmailEnabled';

export const FormContent = ({
  setFieldValue,
  values,
}: {
  setFieldValue: FormikHelpers<AccountSetupFormValues>['setFieldValue'];
  values: AccountSetupFormValues;
}) => {
  const { formatMessage } = useIntl();
  const { isEmailEnabled } = values;

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { name, checked },
  }) => {
    setFieldValue(name, checked);
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: SUB_PRODUCT_AREA.ACCOUNT_SET_UP,
      screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
      ...analyticsPropsFnMap[name](checked),
    });
  };

  return (
    <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
      <FormField
        id={PREFERRED_FIRST_NAME}
        name={PREFERRED_FIRST_NAME}
        label={formatMessage({ id: 'STR_PREFERRED_NAME' })}
      >
        <Field as={TextInput} />
      </FormField>
      <div>
        {/* <Field
          as={Checkbox}
          label={<TermAndConditionsText showIhaveRead />}
          id={IS_TERMS_ACCEPTED}
          name={IS_TERMS_ACCEPTED}
          checked={isTermsAccepted}
          onChange={handleCheckboxChange}
        /> */}
      </div>
      <div>
        <Field
          as={Checkbox}
          label={formatMessage({
            id: 'STR_RECEIVE_OCCASIONAL_EMAIL',
          })}
          id={IS_EMAIL_ENABLED}
          name={IS_EMAIL_ENABLED}
          checked={isEmailEnabled}
          onChange={handleCheckboxChange}
          hint={
            <StackLayout
              spacing="$one"
              css={{ paddingTop: '$quarter' }}
              aria-live="polite"
            >
              {/* {isEmailEnabled && email && (
                <ParagraphText
                  size="xs"
                  css={{ '&&': { marginBlockEnd: '$none' } }}
                  data-testid="email-hint"
                >
                  {formatMessage(
                    { id: 'STR_ONBOARDING_EMAIL_HINT' },
                    { email },
                  )}
                </ParagraphText>
              )} */}
              <ParagraphText>
                {formatMessage({ id: 'STR_EMAIL_LEGAL_DISCLAIMER' })}
              </ParagraphText>
            </StackLayout>
          }
        />
      </div>
    </StackLayout>
  );
};
