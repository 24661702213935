import React from 'react';
import { useHistory } from '@leagueplatform/routing';
import {
  BackButtonArrow,
  PageHeaderBackground,
} from '@leagueplatform/web-common-components';
import { Box, HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { AuthorizationStatus } from 'utils/get-translated-status-label';
import { AuthorizationStatusBar } from './authorization-status-banner.component';

export interface AuthorizationCardProps {
  heading: string;
  status: AuthorizationStatus;
}

const statusColors: Record<AuthorizationStatus, string> = {
  approved: '#D5F2E7',
  partiallyApproved: '#FFE4BB',
  inProgress: '#F0F0F0',
  denied: '#F9DEE3',
};

export const AuthorizationDetailsHeader: React.FC<AuthorizationCardProps> = ({
  heading,
  status,
}) => {
  const history = useHistory();
  const backgroundColor = statusColors[status];

  return (
    <PageHeaderBackground
      height="154px"
      pageHeaderBackgroundHeight="154px"
      css={{
        '&:before': {
          backgroundColor: `${backgroundColor} !important`,
        },
      }}
    >
      <Box css={{ width: '100%' }}>
        <StackLayout
          orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
          css={{ alignItems: 'baseline' }}
        >
          <BackButtonArrow
            fontSize="heading3"
            color="onSurface.text.primary"
            marginRight="one"
            marginY="half"
            justifyContent="initial"
            onClick={history.goBack}
          />
          <HeadingText
            level="1"
            size={{ '@initial': 'xxl', '@mobile': 'lg' }}
            css={{ marginBlock: '$one $one' }}
          >
            {heading}
          </HeadingText>
        </StackLayout>
        <StackLayout
          css={{
            flex: 1,
            paddingLeft: '50px',
          }}
        >
          <AuthorizationStatusBar status={status} />
        </StackLayout>
      </Box>
    </PageHeaderBackground>
  );
};
