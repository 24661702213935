// Auto-generated file: Do not modify directly. Changes may be overwritten. Version: 1.2.0.

/*
 * Following tokens have been deprecated in version 1.2.0, please update your theme:
 *
 * Interactive/Focus has been split into two separate tokens. Please define 'Interactive/Focus Inner' and 'Interactive/Focus Outer' instead.
 * 'Interactive/Background Default' is deprecated. Please use 'Input/Background Default' instead.
 * 'Interactive/Background Disabled' is deprecated. Please use 'Input/Background Disabled' instead.
 * 'Interactive/Background Hovered' is deprecated. Please use 'Input/Background Hovered' instead.
 * 'Interactive/Background Pressed' is deprecated. Please use 'Input/Background Pressed' instead.
 * 'Interactive/Border Default' is deprecated. Please use 'Input/Border Default' instead.
 * 'Interactive/Border Hovered' is deprecated. Please use 'Input/Border Hovered' instead.
 * 'Interactive/Border Disabled' is deprecated. Please use 'Input/Border Disabled' instead.
 * 'Interactive/Border Critical Default' is deprecated. Please use 'Input/Border Critical' instead.
 * 'Pill' is deprecated. Please use 'Circle' instead.
 */

import type { GDSTheme } from '@leagueplatform/genesis-core';

export const santaClaraTheme: GDSTheme = {
  colors: {
    surface: {
      background: {
        primary: '#FFFFFFFF',
        secondary: '#F8F8F8FF',
        tertiary: '#FFF9F3FF',
      },
      card: {
        primary: '#FFF',
        secondary: '#FFFFFFFF',
        disabled: '#D7D7D7FF',
      },
      screenHeader: { background: '#DEF3FCFF' },
      skeleton: {
        shimmer: {
          background: '#E7E7E7FF',
          overlay:
            'linear-gradient(90.0deg, #FFFFFF00 0%, #FFFFFF80 50%, #FFFFFFE5 70%, #FFFFFF00 100%)',
        },
      },
    },
    onSurface: {
      text: {
        primary: '#050F24FF',
        subdued: '#5C6370FF',
        reversed: '#FFFFFFFF',
        critical: '#D3232CFF',
        success: '#00804DFF',
        warning: '#CF6902FF',
      },
      border: { subdued: '#D6D6D6FF', default: '#5C6370FF', card: '#D6D6D6FF' },
      icon: { primary: '#E26912FF' },
    },
    interactive: {
      action: {
        primary: '#2D7B7AFF',
        hovered: '#316360FF',
        pressed: '#21453EFF',
        subdued: '#5C6370FF',
        disabled: '#D6D6D6FF',
      },
      icon: { default: '#FFFFFFFF', disabled: '#767676FF' },
      background: {
        default: '#FFFFFFFF',
        disabled: '#F0F0F0FF',
        hovered: '#F8F8F8FF',
        pressed: '#F0F0F0FF',
      },
      border: {
        default: '#70747CFF',
        hovered: '#2D7B7AFF',
        disabled: '#D6D6D6FF',
        critical: { default: '#D3232CFF' },
      },
      focus: { inner: '#2D7B7AFF', outer: '#2D7B7AFF' },
    },
    critical: {
      background: { subdued: '#F9DEE3FF', secondary: '#FFFFFFFF' },
      border: { default: '#D3232CFF' },
      icon: '#D3232CFF',
    },
    warning: {
      background: { subdued: '#FFE4BBFF', secondary: '#FFFFFFFF' },
      border: { default: '#CF6902FF' },
      icon: '#CF6902FF',
    },
    success: {
      background: { subdued: '#D5F2E7FF', secondary: '#FFFFFFFF' },
      border: { default: '#00A865FF' },
      icon: '#00A865FF',
    },
    highlight: {
      background: { subdued: '#D9F3F3FF', secondary: '#FFFFFFFF' },
      border: { default: '#00BAAEFF' },
      icon: '#00BAAEFF',
    },
    decorative: {
      brand: {
        primary: {
          darkest: '#0D3835FF',
          dark: '#316360FF',
          default: '#48A19DFF',
          bright: '#62C5C0FF',
          brightest: '#D9F3F3FF',
          pastel: '#EEF9F9FF',
        },
        secondary: {
          darkest: '#7A4725FF',
          dark: '#A25A2AFF',
          default: '#E26912FF',
          bright: '#F8A260FF',
          brightest: '#FAD3AEFF',
          pastel: '#FAF0E7FF',
        },
        tertiary: {
          darkest: '#3F3754FF',
          dark: '#595277FF',
          default: '#7768B3FF',
          bright: '#9C92D6FF',
          brightest: '#CDC8EFFF',
          pastel: '#F5F2FFFF',
        },
      },
    },
    primary: {
      background: {
        default: '#E26912FF',
        hovered: '#EF6E12FF',
        pressed: '#CD5E0EFF',
        critical: {
          default: '#D3232CFF',
          hovered: '#A11B22FF',
          pressed: '#6E1217FF',
        },
      },
      text: { default: '#FFFFFFFF', critical: { default: '#FFFFFFFF' } },
    },
    secondary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#FAF0E7FF',
        pressed: '#FFFFFFFF',
      },
      text: { default: '#E26912FF', hovered: '#FFFFFFFF' },
      border: { default: '#E26912FF' },
    },
    tertiary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#EF6E12FF',
        pressed: '#CD5E0EFF',
      },
      text: { default: '#5C6370FF', hovered: '#FFFFFFFF' },
      border: { default: '#767676FF' },
    },
    input: {
      border: {
        success: '#00A865FF',
        warning: '#CF6902FF',
        default: '#70747CFF',
        hovered: '#2D7B7AFF',
        disabled: '#D6D6D6FF',
        critical: '#D3232CFF',
      },
      background: {
        default: '#FFFFFFFF',
        disabled: '#F0F0F0FF',
        hovered: '#F8F8F8FF',
        pressed: '#F0F0F0FF',
      },
    },
    dataViz: {
      interface: { track: { border: '#959595FF', background: '#F5F5F5FF' } },
      singleColor: { brand: '#008BCCFF', neutral: '#959595FF' },
      status: {
        critical: '#CB2A32FF',
        warning: '#DE6F12FF',
        success: '#159369FF',
      },
      categorical: {
        one: '#008BCCFF',
        two: '#074664FF',
        three: '#00699AFF',
        four: '#C2891BFF',
        five: '#845A09FF',
        six: '#008B82FF',
      },
    },
  },
  shadows: {
    card: { x: 0, y: 2, blur: 6, spread: 0, color: '#0000001F' },
    dropdown: { x: 0, y: 4, blur: 8, spread: 0, color: '#0000001A' },
  },
  typography: {
    headingOne: {
      fontFamily: 'Lato',
      fontWeight: 700,
      lineHeight: 40,
      fontSize: 32,
      letterSpacing: 0.25,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingTwo: {
      fontFamily: 'Lato',
      fontWeight: 700,
      lineHeight: 32,
      fontSize: 24,
      letterSpacing: 0.25,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingThree: {
      fontFamily: 'Lato',
      fontWeight: 700,
      lineHeight: 28,
      fontSize: 20,
      letterSpacing: 0.25,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingFour: {
      fontFamily: 'Lato',
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 18,
      letterSpacing: 0.25,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOneSecondary: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwoSecondary: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonOne: {
      fontFamily: 'Lato',
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    label: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    overline: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'UPPER',
      textDecoration: 'none',
    },
    caption: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    tab: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 12,
      fontSize: 10,
      letterSpacing: 0.1599999964237213,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
  },
  spacing: {
    none: 0,
    quarter: 4,
    half: 8,
    threeQuarters: 12,
    one: 16,
    oneAndHalf: 24,
    two: 32,
    twoAndHalf: 40,
    three: 48,
    four: 64,
    five: 80,
  },
  borderWidths: { thin: 1, thick: 2, outerFocus: 3, innerFocus: 3, card: 1 },
  borderRadii: {
    none: 0,
    thin: 1,
    thick: 2,
    small: 3,
    medium: 6,
    large: 9,
    extraLarge: 12,
    huge: 20,
    circle: 99999,
    pill: 99999,
    button: 9999,
    inputField: 6,
    card: 6,
  },
  zIndices: {
    default: 1,
    sticky: 100,
    dropdown: 4000,
    tooltip: 5000,
    modal: 8000,
    toast: 9000,
  },
  rem: {
    fontSizes: {
      headingOne: 2,
      headingTwo: 1.5,
      headingThree: 1.25,
      headingFour: 1.125,
      subtitleOne: 1,
      subtitleTwo: 0.875,
      bodyOne: 1,
      bodyOneSecondary: 1,
      bodyTwo: 0.875,
      bodyTwoSecondary: 0.875,
      buttonOne: 1,
      buttonTwo: 0.875,
      label: 0.75,
      overline: 0.75,
      caption: 0.75,
      tab: 0.625,
    },
    lineHeights: {
      headingOne: 2.5,
      headingTwo: 2,
      headingThree: 1.75,
      headingFour: 1.5,
      subtitleOne: 1.5,
      subtitleTwo: 1.25,
      bodyOne: 1.5,
      bodyOneSecondary: 1.5,
      bodyTwo: 1.25,
      bodyTwoSecondary: 1.25,
      buttonOne: 1.5,
      buttonTwo: 1.25,
      label: 1,
      overline: 1,
      caption: 1,
      tab: 0.75,
    },
    baseSize: 16,
  },
  fonts: {
    headings: 'Lato',
    body: "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  },
  transitions: { defaultTime: '200ms' },
};
