import { Suspense } from 'react';

import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from '@leagueplatform/routing';
import League from '@leagueplatform/core';
import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  MasonryDriverNodeRenderersRegistry,
  MasonryWSDriverNodeRenderersRegistry,
} from '@leagueplatform/masonry';
import { RecentClaimsCardList } from '@leagueplatform/claims';
import { ActivityStackWidgetV2 } from '@leagueplatform/health-journey';
import {
  FullPageError,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { PCPCustomSectionRenderer } from 'components/masonry/pcp-custom-section-renderer.component';
import { CustomerServiceCard } from 'components/cards/customer-service-card.component';
import { StatusBannerRenderer } from 'components/masonry/status-banner-renderer.component';
import { SuggestedProgramsCarousel } from 'components/carousels/suggested-programs-carousel.component';
import { useGetLeagueConfig } from 'hooks/use-get-league-config.hook';

import { GDSThemeProvider, Spinner } from '@leagueplatform/genesis-core';
import { santaClaraTheme } from 'theme/old-santaClaraLightTheme';
import { AuthorizationCard } from 'components/cards/authorization-card.component';
import { AuthorizationServiceCard } from 'components/cards/authorization-service-card.component';
import { AuthorizationServiceInfoSection } from 'components/cards/authorization-service-info-section.component';
import { AuthorizationDetailsHeader } from 'components/authorization-details-header.component';
import { EmptyState } from 'components/empty-state.component';
import AppRoutes from './components/AppRoutes';
import { routesConfig, authlessConfig, STANDALONE_AUTH_CONFIG } from './config';

League.overwriteRoutePaths(routesConfig);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'scfhp',
  'your_care_team_stack',
  PCPCustomSectionRenderer,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'health_journey',
  'activity_stack',
  ActivityStackWidgetV2,
);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'claims',
  'recent_claims_cards',
  RecentClaimsCardList,
);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'scfhp',
  'contact_info_card',
  CustomerServiceCard,
);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'default',
  'carousel',
  SuggestedProgramsCarousel,
);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'scfhp',
  'status_banner',
  StatusBannerRenderer,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'scfhp_authorizations',
  'authorizationCard',
  AuthorizationCard,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'scfhp_authorizations',
  'authorizationsEmptyState',
  EmptyState,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'scfhp_authorization_details',
  'authorizationServiceCard',
  AuthorizationServiceCard,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'scfhp_authorization_details',
  'authorizationServiceInfoSection',
  AuthorizationServiceInfoSection,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'scfhp_authorization_details',
  'authorizationDetailsHeader',
  AuthorizationDetailsHeader,
);

export function App() {
  const { isOffline, isOnline } = useOnlineStatus();

  const { isLoading, config } = useGetLeagueConfig(
    authlessConfig,
    STANDALONE_AUTH_CONFIG,
  );

  if (isLoading) {
    return (
      <GDSThemeProvider theme={santaClaraTheme}>
        <Spinner
          loading
          loadingText="Loading..."
          css={{
            display: 'block',
            width: '100vw',
            height: '100vh',
            alignContent: 'center',
            textAlign: 'center',
          }}
        />
      </GDSThemeProvider>
    );
  }

  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary fallback={<ErrorState />}>
        <Suspense fallback={<LoadingIndicator />}>
          {isOffline && <FullPageError route="/home" />}
          {isOnline && (
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
}
