import React from 'react';
import {
  Card,
  HeadingText,
  StackLayout,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { AuthorizationStatusBar } from 'components/authorization-status-banner.component';
import { AuthorizationStatus } from 'utils/get-translated-status-label';

export interface AuthorizationServiceCardProps {
  heading: string;
  status: AuthorizationStatus;
  info: {
    key: string;
    value: string;
  }[];
}

export const AuthorizationServiceCard: React.FC<
  AuthorizationServiceCardProps
> = ({ heading, info, status }) => {
  const { formatMessage } = useIntl();

  const getTranslatedKey = (key: string): string => {
    const translationKeys: Record<string, string> = {
      'Request Type': 'STR_REQUEST_TYPE',
      'Request Date': 'STR_REQUEST_DATE',
      'Date of Service': 'STR_DATE_OF_SERVICE',
      'Service Code': 'STR_SERVICE_CODE',
      'Service Type': 'STR_SERVICE_TYPE',
      Units: 'STR_UNITS',
      'Diagnosis Code': 'STR_DIAGNOSIS_CODE',
    };

    return formatMessage({ id: translationKeys[key] as any });
  };

  return (
    <Card.Elevated
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        boxShadow: 'none',
        borderRadius: '8px',
        backgroundColor: 'inherit',
        border: '1px solid #D6D6D6',
      }}
    >
      <StackLayout
        css={{
          flex: 1,
        }}
      >
        <AuthorizationStatusBar status={status} />
      </StackLayout>
      <HeadingText
        level="4"
        size="xl"
        css={{
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '32px',
          letterSpacing: '0.25px',
          textAlign: 'left',
          marginTop: '$one',
          marginBottom: '$two',
        }}
      >
        {heading}
      </HeadingText>
      <StackLayout
        orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
        css={{ gap: '$one', alignItems: 'start' }}
      >
        {info.map(({ key, value }) => (
          <StackLayout
            key={key}
            orientation="vertical"
            css={{
              padding: '$half',
            }}
          >
            <ParagraphText css={{ fontWeight: 700, fontSize: '14px' }}>
              {getTranslatedKey(key)}
            </ParagraphText>
            <ParagraphText>{value}</ParagraphText>
          </StackLayout>
        ))}
      </StackLayout>
    </Card.Elevated>
  );
};
