import React, { useEffect } from 'react';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useAuth } from '@leagueplatform/auth-utils';
import { useHistory } from '@leagueplatform/routing';
import { useUserEligibility } from 'hooks/use-user-eligibility.hook';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { NotEligibleScreen } from 'components/error/not-eligible-screen.component';
// import { buildErrorUrlParams } from 'utils/auth-error-helpers';
// import { AUTH0_ERROR_TYPES } from 'common/constants';
// import { StandaloneAuth } from '@leagueplatform/auth-standalone';

export const AuthSessionContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  // const { isAuthenticated, error } = useAuth({
  //   redirectToLogInWhenSignedOut: true,
  // });
  const { isAuthenticated, error } = useAuth();

  const { eligible, loading } = useUserEligibility(isAuthenticated);

  const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    if (error) {
      history.push(`/`);
      // TODO: Need Approval first:
      // Redirect to login page if inactivity error occurs
      // if (error.type === AUTH0_ERROR_TYPES.LOGIN_REQUIRED) {
      //   StandaloneAuth.client.loginWithRedirect({
      //     appState: {
      //       redirectPath: `${location.pathname}?${location.search}`,
      //     },
      //   });
      // } else {
      //   // Redirect to landing page if other auth errors occur
      //   const errorUrlParams = buildErrorUrlParams(error);
      //   history.push(`/?${errorUrlParams}`);
      // }
    }
  }, [error, history]);

  if (!isAuthenticated || loading) return <LoadingIndicator />;

  // Handle eligibility errors or ineligibility
  if (eligible === false) {
    return <NotEligibleScreen errorType={ErrorTypes.NOT_ELIGIBLE} />;
  }

  return children;
};
