import React from 'react';
import { HeadingText, ParagraphText } from '@leagueplatform/genesis-core';

const HeaderSection: React.FC<{
  title: string;
  subTitle?: string;
}> = ({ title, subTitle }) => (
  <>
    <HeadingText
      size="md"
      css={{
        fontSize: '24px',
        fontWeight: '700',
        lineHeight: '32px',
      }}
      level="3"
    >
      {title}
    </HeadingText>
    {subTitle && (
      <ParagraphText
        css={{
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          marginY: '16px',
        }}
      >
        {subTitle}
      </ParagraphText>
    )}
  </>
);

export default HeaderSection;
