export type AuthorizationStatus =
  | 'approved'
  | 'partiallyApproved'
  | 'inProgress'
  | 'denied';

export const getStatusTranslationKey = (
  status: AuthorizationStatus,
): string => {
  const translationKeys: Record<AuthorizationStatus, string> = {
    approved: 'STR_APPROVED',
    partiallyApproved: 'STR_PARTIALLY_APPROVED',
    inProgress: 'STR_IN_PROGRESS',
    denied: 'STR_DENIED',
  };

  return translationKeys[status];
};
