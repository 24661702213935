export const TENANT_ID = 'scfhp';

export const MASONRY = {
  API_VERSION: 2,
  CUSTOM_WIDGET_TYPE_HEALTHLINK_LOGO: 'healthlink_logo',
  HEALTH_JOURNEY_MODULE_KEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
  MESSAGING_MODULE_KEY: 'messaging',
  CUSTOM_WIDGET_TYPE_MESSAGING: 'recent_messages',
  MODULE_KEY_CLAIMS: 'claims',
  CUSTOM_WIDGET_TYPE_CLAIMS: 'recent_claims_cards',
  CUSTOM_WIDGET_TYPE_ACTIVITY: 'activity_stack',
};

export const HealthAssessment = {
  campaignId: '2bufDAAikyXO8JKm1L1o4r',
  activityId: '2VLBZ2tDAOVBYVAoo0ExUR',
};

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'scfhp-web',
  NO_INFO_AVAILABLE: 'no info available',
};

export const COMMUNICATION_OPTIONS = {
  SMS: 'sms',
  EMAIL: 'email',
};

export const MESSAGE_TYPES = {
  GET_APP_INIT_DETAILS: 'get_app_init_details',
  GET_WIDGET_CONTAINER: 'get_widget_container',
};

export const PATHS = {
  COMMUNICATION_PREFERENCES: '/settings/communication-preferences',
  ABOUT: '/settings/about',
  EN_TERMS_OF_USE_ROUTE: 'https://www.scfhp.com/terms-and-conditions',
  EN_PRIVACY_POLICY: 'https://www.scfhp.com/privacy-policy/',
  CONTACT_US: '/contact_info',
  FORMS: '/forms',
  CHANGE_PCP: '/change-pcp',
  AUTH_STATUSES_PAGE: '/authorization-statuses',
  AUTH_STATUS_PAGE: '/authorization-status',

  PERSONAL_INFO: '/settings/personal-information',
  EN_SUBMIT_REQUEST_DUALCONNECT:
    'https://www.scfhp.com/forms/grievance-and-appeal-form-dualconnect/',
  EN_SUBMIT_REQUEST_MEDICAL:
    'https://www.scfhp.com/healthcare-plans/medi-cal/frequently-asked-questions/grievance-and-appeal-process/',
  EN_CALENDER_OF_EVENTS: 'https://crc.scfhp.com/crc-calendar/',
  EN_COVERAGE_DETERMINATION_FORM:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/534390fc09f64d48bd15c212df0e31f0/h4045_23106e-redetermination-request-form.pdf',
  EN_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/c5796e14c6934a9b9c6f02d02fbe129a/23015e-nondiscrimination-notice.pdf',
  ES_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/170f93bc7f7a439ebf2ab5010e932a66/23015s-nondiscrimination-notice.pdf',
  VT_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/51b0949186e64b36b3c9bc5ebefc8a16/23015v-nondiscrimination-notice.pdf',
  TC_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/839dff94798b498cbab6004a9ee01742/23015c-nondiscrimination-notice.pdf',
  CN_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/0936be4793614e61a4da7676dd91cbb2/23015t-nondiscrimination-notice.pdf',
  EN_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/da546163ae774c47a455ca801c3b3a85/50514e-mc-nondiscrimination-notice.pdf',
  ES_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/04baa3b33efa4e6790ac6316ff61b06a/50514s-mc-nondiscrimination-notice.pdf',
  VT_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/51b0949186e64b36b3c9bc5ebefc8a16/23015v-nondiscrimination-notice.pdf',
  CN_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/0304583918094a2697d39f4ac9e99e1c/50514c-mc-nondiscrimination-notice.pdf',
  TG_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/14426d5b2c4b430e837b9a0c7902fe34/50514t-mc-nondiscrimination-notice.pdf',
  LANGUAGE_ASSISTANCE_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/46d072a5aaac4573aea97fee44ad7232/mchk_las.pdf',
  LANGUAGE_ASSISTANCE_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/4a0ec322d3bf45c590fd796db01dbe41/24077-dc-las.pdf',
  FACEBOOK: 'https://www.facebook.com/scfhp/',
  DELEGATE_CONTACT_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/1c338151e73648d9b21268f2c22ebde2/scfhpquickreferenceguide.pdf',
  INSTAGRAM: 'https://www.instagram.com/scfhp/',
  WEBSITE: 'https://www.scfhp.com',
  LINKEDIN: 'http://www.linkedin.com/company/santa-clara-family-health-plan',
};

export const REST_ENDPOINTS = {
  USER: '/v1/users',
};

export const NOT_ELIGIBLE_CONTACT = [
  {
    title: 'Medi-Cal',
    email: 'CallCenterManagement@scfhp.com',
    phone: '1-833-987-2347',
    hours: 'Monday through Friday, 8:30 a.m. to 5 p.m.',
  },
  {
    title: 'DualConnect',
    email: 'ContactDualConnect@scfhp.com',
    phone: '1-833-987-2347',
    hours: '7 days a week, 8 a.m. to 8 p.m.',
  },
];

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];
