import { forwardRef, Ref } from 'react';
import {
  Box,
  Button,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const FixedFormFooter = forwardRef(
  (
    {
      back,
      next,
      dirty,
      isLoading,
      submitText,
      returnText,
      customStyle,
    }: {
      back: () => void;
      next: () => void;
      dirty?: boolean;
      isLoading?: boolean;
      submitText: string;
      returnText?: string;
      customStyle?: any;
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const { formatMessage } = useIntl();
    return (
      <Box
        ref={ref}
        css={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          backgroundColor: '$surfaceBackgroundPrimary',
          boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
          paddingInline: '$twoAndHalf',
          marginInline: 'auto',
          paddingBlock: '$one',
          '@mobile': {
            position: 'relative',
            boxShadow: 'none',
            paddingInline: '$oneAndHalf',
            paddingBlock: '$oneAndHalf $one',
          },
        }}
      >
        {dirty && (
          <div role="region" aria-live="polite">
            <UtilityText
              as="p"
              emphasis="subtle"
              css={{
                textAlign: 'center',
                '@mobile': { marginBottom: '$oneAndHalf' },
              }}
            >
              {formatMessage({ id: 'STR_YOU_HAVE_UNSAVED' })}
            </UtilityText>
          </div>
        )}
        <StackLayout
          orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
          horizontalAlignment={{ '@initial': 'end', '@mobile': 'stretch' }}
          spacing="$one"
          css={{
            ...customStyle,
          }}
        >
          <Button
            priority="secondary"
            onClick={back}
            width={{ '@initial': 'hugContents', '@mobile': 'fillContainer' }}
            css={{
              borderRadius: '9999px !important',
              padding: '4px 32px !important',
            }}
          >
            {returnText || formatMessage({ id: 'STR_BACK' })}
          </Button>
          <Button
            loading={isLoading}
            priority="primary"
            type="submit"
            onClick={next}
            width={{ '@initial': 'hugContents', '@mobile': 'fillContainer' }}
            css={{
              borderRadius: '9999px !important',
              padding: '4px 32px !important',
            }}
          >
            {submitText}
          </Button>
        </StackLayout>
      </Box>
    );
  },
);
